@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h3>ข้อมูลผู้ใช้งาน</h3>
    <v-row class="text-end">
      <v-col cols="2"
        ><p class="pt-2"><b>ชื่อจริง:</b></p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="firstName"
          placeholder="ชื่อจริง"
          solo
          dense
          type="text"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"
        ><p class="pt-2"><b>นามสกุล:</b></p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="lastName"
          placeholder="นามสกุล"
          solo
          dense
          type="text"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-end">
      <v-col cols="2"
        ><p class="pt-2"><b>อีเมล:</b></p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="email"
          placeholder="อีเมล"
          solo
          dense
          type="text"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2"
        ><p class="pt-2"><b>หมายเลขโทรศัพท์:</b></p></v-col
      >
      <v-col cols="4">
        <v-text-field
          v-model="telNumber"
          placeholder="เบอร์โทรศัพท์"
          solo
          dense
          type="text"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider class="mb-6"></v-divider>

    <!-- <v-row>
      <v-col cols="4"><p class="pt-2">รูปบัตรประจำตัวประชาชน</p></v-col>
      <v-img :src="idCardImgUrl"></v-img>
    </v-row>
    <v-row>
      <v-col cols="4"><p class="pt-2">รูปถ่ายคู่บัตรประชาชน</p></v-col>
      <v-img :src="idCardWithPersonImgUrl"></v-img>
    </v-row> -->

    <v-row>
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()" color="primary">ตกลง</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      telNumber: "",
      activeFlag: false,
    };
  },
  created() {
    // var userdata = JSON.parse(
    //   Decode.decode(localStorage.getItem("dealerdata"))
    // );
    // console.log("userdata", userdata);
    // this.id = userdata.id;
    // this.firstName = userdata.firstName;
    // this.lastName = userdata.lastName;
    // this.email = userdata.email;
    // this.tel = userdata.tel;
    // this.activeFlag = userdata.activeFlag;
    // this.agentNo = userdata.agentNo;
    // this.bankName = userdata.bankName;
    // this.bankNo = userdata.bankNo;
    // this.member = userdata.member;
    // this.idCardImgUrl = userdata.idCardImgUrl;
    // this.idCardWithPersonImgUrl = userdata.idCardWithPersonImgUrl;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    cancel() {
      this.$router.push("ManageAdmin");
    },
    async submit() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("userdata", user);
      const data = {
        // id: this.id,
        firstName: this.firstName,
        lastName: this.lastName,
        telNumber: this.telNumber,
        email: this.email,
      };
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var user = JSON.parse(
          //   Decode.decode(localStorage.getItem("DDLotAdmin"))
          // );
          // const auth = {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/users/createAdmin`,
            data,
            auth
          );
          console.log(response);

          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `บันทึกข้อมูลสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            // await location.reload();
            this.$router.push("ManageAdmin");
          } else {
            this.$swal.fire({
              icon: "error",
              text: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      });
    },
  },
};
</script>
